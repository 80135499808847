module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.9.0_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.48.0__react-dom@18.2._xqhbigomcqm36a34wvwyi2ebgy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Logto Blog","short_name":"Logto Blog","lang":"en","display":"standalone","icon":"src/assets/favicon.png","start_url":"/","background_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b82584cec87d0380b3e2d97b2ab0b130"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.10.0_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.48.0__react-dom@1_kjzjmqtmm44cgqrw2bwilqldcu/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-11124811245"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.0_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.48.0__react-_v2s62e5muylqshqii52fkollz4/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.8.0_gatsby-plugin-sharp@5.8.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint_zz7afrng4vpbxklmlk3hnmykc4/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"#eee","maxWidth":1280,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.48.0__react-dom@18.2.0_react@18.2.0__react@18.2.0__ozemak2a3ys434midlzc7egzty/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
