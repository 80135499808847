import { Helmet } from 'react-helmet';

import LogtoBlogImage from '@/assets/images/logto-blog.png';
import { title, description, logtoBlogUrl } from '@/consts/urls';

import ImageMeta from './ImageMeta';

type Props = {
  location: Location;
};

const SiteMeta = ({ location }: Props) => {
  const canonical = new URL(location.pathname, logtoBlogUrl).toString();
  const imageUrl = new URL(LogtoBlogImage, logtoBlogUrl).toString();

  return (
    <>
      <Helmet>
        <html lang="en"></html>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        <script
          defer
          data-domain="logto.io"
          data-api="https://akasha.logto.io/placebo/eagan"
          src="https://akasha.logto.io/placebo/sabaean.js"
        ></script>
        <script>
          {`window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) };`}
        </script>
      </Helmet>
      <ImageMeta shareImage={imageUrl} />
    </>
  );
};

export default SiteMeta;
