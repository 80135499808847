import { type Nullable } from '@silverhand/essentials';
import { createContext, createRef } from 'react';

export type LayoutContextType = {
  topBarRef: React.RefObject<Nullable<HTMLDivElement>>;
};

export const LayoutContext = createContext<LayoutContextType>({
  topBarRef: createRef<Nullable<HTMLDivElement>>(),
});
