import { Component, GeneralEvent } from '@logto/app-insights/custom-event';
import {
  AppInsightsContext,
  AppInsightsProvider,
  TrackOnce,
  getPrimaryDomain,
} from '@logto/app-insights/react';
import { type WrapPageElementBrowserArgs } from 'gatsby';
import { useContext, useEffect, useState } from 'react';

import Layout from '@/layouts/Layout';
import initI18n from '@/utils/i18n';

type Props = {
  pathname: string;
};

void initI18n();

const AppInsights = ({ pathname }: Props) => {
  const { needsSetup, setup, isSetupFinished, appInsights } = useContext(AppInsightsContext);
  const [pageViewTracked, setPageViewTracked] = useState<string>();

  const connectionString = process.env.GATSBY_APPLICATIONINSIGHTS_CONNECTION_STRING;

  useEffect(() => {
    const run = async () => {
      await setup('blog', {
        cookieDomain: getPrimaryDomain(),
        connectionString,
      });
    };

    if (needsSetup) {
      void run();
    }
  }, [connectionString, needsSetup, setup]);

  useEffect(() => {
    // Only track once for the same page
    if (isSetupFinished && pageViewTracked !== pathname) {
      appInsights.trackPageView?.({ name: `Blog: ${pathname}` });
      setPageViewTracked(pathname);
    }
  }, [appInsights, isSetupFinished, pageViewTracked, pathname]);

  return null;
};

export const wrapPageElement = ({ element, props }: WrapPageElementBrowserArgs) => {
  return (
    <AppInsightsProvider>
      <AppInsights pathname={props.path}></AppInsights>
      <TrackOnce component={Component.Blog} event={GeneralEvent.Visit}></TrackOnce>
      <Layout {...props}>{element}</Layout>
    </AppInsightsProvider>
  );
};
