import { appendPath } from '@silverhand/essentials';

export const logtoWebsiteUrl = 'https://logto.io/';
export const logtoPricingUrl = 'https://logto.io/pricing';
export const logtoCloudSignInUrl = 'http://cloud.logto.io';
export const logtoCloudSignUpUrl = 'http://cloud.logto.io?sign_up=true';
export const aboutCloudUrl = 'https://docs.logto.io/about/cloud-preview/';
export const termsOfUseUrl = 'https://docs.logto.io/terms/of-use/cloud/';
export const privacyPolicyUrl = 'https://docs.logto.io/terms/privacy-policy/';
export const documentationBaseUrl = 'https://docs.logto.io/';
export const logtoGitHubRepo = 'https://github.com/logto-io/logto';
export const logtoGitHubApiEndpoint = 'https://api.github.com/repos/logto-io/logto';
export const roadmapUrl = 'https://github.com/logto-io/logto/issues/1937';
export const logtoBlogUrl = 'https://blog.logto.io';
export const contactUsUrl = 'mailto:contact@logto.io';
export const discordUrl = 'https://discord.gg/UEPaF3j5e6';
export const twitterUrl = 'https://twitter.com/logto_io';

const _documentationBaseUrl = new URL(documentationBaseUrl);
const _logtoWebsiteUrl = new URL(logtoWebsiteUrl);

export const apiDocumentationUrl = appendPath(
  _documentationBaseUrl,
  'docs/recipes/interact-with-management-api/'
).toString();

export const aboutUsUrl = appendPath(_logtoWebsiteUrl, 'about').toString();
export const subscribeUrl = appendPath(_logtoWebsiteUrl, 'subscription').toString();
export const apiReferenceUrl = appendPath(_documentationBaseUrl, 'api').toString();
export const sdkGuideUrl = appendPath(
  _documentationBaseUrl,
  'docs/recipes/integrate-logto/'
).toString();

export const title = 'Logto Blog';
export const description =
  'Logto is an Auth0 alternative for building modern customer identity infrastructure with minimal effort, for both your customers and their organizations.';
export const blogPublicUrl = 'https://blog.logto.io';
export const logtoBlogCoverImage = '/assets/images/logto-blog.png';
