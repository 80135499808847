import {
  findI18nResourceByLanguage,
  i18nNamespace,
  fallbackLanguage,
  fallbackLanguagePhrases,
  type Language,
} from '@logto/website-components/i18n';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const initI18n = async (language?: Language) => {
  const lng = language ?? fallbackLanguage;

  const loadResources = async () => {
    const componentResources = await findI18nResourceByLanguage(lng);

    if (componentResources) {
      i18next.addResourceBundle(lng, i18nNamespace, componentResources);
    }

    await i18next.changeLanguage(lng);
  };

  if (i18next.isInitialized) {
    console.warn('i18n is already initialized, loading resources only');
    void loadResources();
    return;
  }

  // Ensure the fallback language is loaded first, then asynchronously load the requested language.
  const promise = i18next.use(initReactI18next).init({
    detection: {
      caches: ['localStorage'],
      order: ['localStorage', 'domain'],
    },
    lng,
    resources: {
      [fallbackLanguage]: {
        [i18nNamespace]: fallbackLanguagePhrases,
      },
    },
    fallbackLng: fallbackLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

  void loadResources();

  return promise;
};

export default initI18n;
