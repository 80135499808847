import { Helmet } from 'react-helmet';

type Props = {
  shareImage?: string;
};

const ImageMeta = ({ shareImage }: Props) => {
  if (!shareImage) {
    return null;
  }

  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={shareImage} />
      <meta property="og:image" content={shareImage} />
    </Helmet>
  );
};

export default ImageMeta;
