import { Footer, Topbar } from '@logto/website-components/shared-sections/index';
import { useMemo, useRef } from 'react';
import { MemoryRouter } from 'react-router-dom';

import { LayoutContext } from '@/context/LayoutContext';

import SiteMeta from '../SiteMeta';

import * as styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
  location: Location;
};

const Layout = ({ children, location }: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const contextValue = useMemo(
    () => ({
      topBarRef: headerRef,
    }),
    []
  );

  return (
    <div className={styles.viewport}>
      <SiteMeta location={location} />
      <LayoutContext.Provider value={contextValue}>
        {/* Wrap topbar and footer in memory router since the website components use react-router inside */}
        <MemoryRouter>
          <Topbar ref={headerRef} isExternal />
          <main className={styles.main}>{children}</main>
          <Footer isExternal languageSelector={false} />
        </MemoryRouter>
      </LayoutContext.Provider>
    </div>
  );
};

export default Layout;
